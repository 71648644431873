<template>
  <el-dialog
    :title="$t('map.xiazai')"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="500px"
    @close="clickFun('close')"
  >
    <div class="ss">
      <div class="search">
        <div class="s-it">
          <el-date-picker
            v-model="search_date"
            type="date"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
        </div>
        <div class="s-it">
          <el-time-picker
            v-model="startTime"
            placeholder="Start time"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
          />
        </div>
        <div class="s-it">
          <el-time-picker
            v-model="endTime"
            placeholder="End time"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
          />
        </div>
        <div class="s-it">
          <el-checkbox-group v-model="sel_channel">
            <el-checkbox
              v-for="item in ay_channel"
              :key="item.key"
              :label="item.value"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div>
          <el-button type="primary" style="width: 45%" @click="btn_download"
            >{{ $t("map.xiazai") }}</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { dateFormat } from "../utils/tools";
import GpsSocket from "../utils/GpsSocket";
export default {
  emits: ["ErpDialogEvent"],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
    file_data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    isShow(val) {
      this.dialogFormVisible = val;
    },
    row(val) {
      let para = val.dPara;
      if (para) {
        let ay = para.split(",");
        let ay_ch = [];
        let i = 1;
        ay.forEach((it) => {
          ay_ch.push({
            name: it,
            value: i++,
          });
        });
        this.ay_channel = ay_ch;
      }
    },
  },
  data() {
    return {
      sel_channel: [],
      ay_channel: [],
      startTime: "00:00:00",
      endTime: "23:59:59",
      search_date: dateFormat("YYYY-mm-dd", new Date()),
      dialogFormVisible: this.isShow,
    };
  },
  methods: {
    PrefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    btn_download() {
      var dev_id = this.PrefixZero(this.row.devID, 12);
      var dt_ss = this.search_date.replace(/-/g, "");
      var st_tt = this.startTime;
      var et_tt = this.endTime;
      //判断开始时间不能大于结束时间啊
      var new_st = dt_ss + st_tt.replace(/:/g, "");
      var new_et = dt_ss + et_tt.replace(/:/g, "");
      if (new_st > new_et || new_st == new_et) {
        this.$message.error("开始时间必须小于结束时间!");
        return false;
      }
      var isThirty = parseInt(new_et) - parseInt(new_st);
      if (isThirty > 6000) {
        // layer.msg(lang.timeError2);
        this.$message.error("一次之多下载三十分钟内的视频文件！");
        return false;
      }

      var chs = this.sel_channel;
      if (chs.length == 0) {
        this.$message.error("请选择通道!");
        return false;
      }

      if (this.file_data.length > 0) {
        var new_file_data = [];
        for (var i = 0; i < this.file_data.length; i++) {
          var begin_time =
            dt_ss +
            this.file_data[i].start_time.split(" ")["1"].replace(/:/g, "");
          var end_time =
            dt_ss +
            this.file_data[i].end_time.split(" ")["1"].replace(/:/g, "");
          //时间过滤
          if (new_st > end_time || new_et < begin_time) {
            continue;
          } else {
            //通道过滤
            if (chs.indexOf(this.file_data[i].channel_num) > -1) {
              new_file_data.push(this.file_data[i]);
            }
          }
        }
        if (new_file_data.length > 0) {
          // layer.msg(lang.ksxz);
          for (var i = 0; i < new_file_data.length; i++) {
            var begin_time =
              dt_ss +
              new_file_data[i].start_time.split(" ")["1"].replace(/:/g, "");
            var end_time =
              dt_ss +
              new_file_data[i].end_time.split(" ")["1"].replace(/:/g, "");
            if (new_st > begin_time) {
              var startTime = this.search_date + " " + st_tt;
            } else {
              var startTime = new_file_data[i].start_time;
            }

            if (new_et > end_time) {
              var endTime = new_file_data[i].end_time;
            } else {
              var endTime = this.search_date + " " + et_tt;
            }
            var obj = {
              deviceid: dev_id,
              order: "9206",
              content: {
                ftpip: "127.0.0.1",
                ftpport: 23333,
                ftpuser: "video",
                ftppwd: "video",
                ftppath: dt_ss + "/" + dev_id,
                sourcetype: "00",
                startdate: startTime,
                enddate: endTime,
                alarmflag: "00",
                bitsstreamtype: "01",
                memorytype: "01",
                channelno: new_file_data[i].channel_num,
                taskexecondition: "07",
              },
            };
            GpsSocket.sendMsg("order", obj);
            // window.parent.get_socket(obj, function (data) {});
          }
        } else {
          this.$message.error(this.$t("map.zanwufuhedexiazaiwenjian"));
          // layer.msg(lang.zwfhdxzwj);
          return false;
        }
      }
    },
    clickOk() {},
    init_data() {},
    clickFun(flag, obj = {}) {
      this.$emit("ErpDialogEvent", Object.assign({ type: flag }, obj));
    },
  },
};
</script>

<style lang="scss" scoped></style>
