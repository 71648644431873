<template>
  <splitpanes class="default-theme dth" @resize="paneSize = $event[0].size">
    <pane :size="paneSize">
      <div class="left">
        <div class="left-1">
          <div class="flex searchs">
            <el-select v-model="value_st" :placeholder="$t('map.qingxuanze')">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input
              clearable
              v-model="query_key"
              :placeholder="$t('map.qingshuruchaxunguanjianzi')"
              @input="onQueryChanged"
            />
          </div>

          <el-tree-v2
            ref="treeRef"
            :data="treeData"
            :height="maxheight"
            :props="propoption"
            :filter-method="filterMethod"
            :default-expanded-keys="defaultExpandedKeys"
            @node-click="nodeClick"
            ><template #default="{ node, data }">
              <span class="prefix"
                ><img
                  :src="data.icon"
                  alt=""
                  style="width: 17px; height: 17px; margin-right: 5px"
              /></span>
              <span style="color: #333">{{ node.label }}</span>
            </template></el-tree-v2
          >
          <div class="ss">
            <div class="search">
              <div class="s-it">
                <el-date-picker
                  v-model="search_date"
                  type="date"
                  placeholder="Pick a day"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                />
              </div>
              <div class="s-it">
                <el-time-select
                  v-model="startTime"
                  placeholder="Start time"
                  start="00:00:00"
                  step="00:01"
                  end="23:59:59"
                  format="HH:mm:ss"
                />
              </div>
              <div class="s-it">
                <el-time-select
                  v-model="endTime"
                  placeholder="End time"
                  start="00:00:00"
                  step="00:01"
                  end="23:59:59"
                  format="HH:mm:ss"
                />
              </div>

              <div>
                <el-button
                  type="primary"
                  style="width: 45%"
                  @click="download_data"
                  >{{ $t("map.xiazai") }}</el-button
                >
                <el-button
                  type="primary"
                  style="width: 45%"
                  @click="search_data"
                  >{{ $t("map.track_cx") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </pane>
    <pane :size="100 - paneSize" style="padding-right: 5px">
      <div
        class="top"
        :style="{ height: 'calc(100% - ' + mapBottomHeight + 'px)' }"
      >
        <div class="row">
          <div class="row-l" :style="{ width: videoW + 'px' }">
            <GpsVideo
              ref="video"
              :width="videoW"
              :height="videoH"
              style="
                border: 1px solid #ccc;
                margin-top: 5px;
                border-right: none;
              "
            ></GpsVideo>
          </div>
          <div
            class="row-r"
            :style="{
              width: 'calc(100% - ' + (videoW + 5) + 'px)',
              height: videoH + 'px',
            }"
            style="overflow: hidden"
          >
            <!-- <LeafletMap ref="mainMap"></LeafletMap> -->
            <GpsMap
              ref="historyMap"
              :isPlay="false"
              style="border: 1px solid #ccc; margin-top: 5px; border-left: none"
            >
            </GpsMap>
          </div>
        </div>
      </div>
      <div
        class="bottom bb"
        :style="{ height: mapBottomHeight + 'px' - 10 + 'px' }"
      >
        <el-tabs v-model="tab_act">
          <el-tab-pane :label="$t('map.shipinhuifang')" name="first">
            <GpsTimeLine
              :isLoadEvent="isLoadEvent"
              :data="timeData"
              @timeEvent="gpsTimeEvent"
            ></GpsTimeLine>
          </el-tab-pane>
          <el-tab-pane :label="$t('map.wenjianxiazai')" name="second">
            <el-table
              :data="dataBackVideo"
              border
              stripe
              :height="mapBottomHeight - 80"
              style="padding-bottom: 10px"
            >
              <el-table-column
                align="center"
                v-for="col in columns"
                :prop="col.field"
                :key="col.field"
                :label="col.label"
                :width="col.width"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <!-- <el-table-column
                fixed="left"
                :label="$t('commKey.Actions')"
                width="80px"
              >
                <template #default="{ row }">
                  <el-button round @click="downloadFile(row)">
                    DOWNLOAD
                  </el-button>
                </template>
              </el-table-column> -->
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <GpsDownloadTime
        :isShow="show_donwload_time"
        @ErpDialogEvent="ErpDialogEvent_time"
        :row="select_tree_node"
        :file_data="dataBackVideo"
      ></GpsDownloadTime>
    </pane>
  </splitpanes>
</template>

<script>
import { fa } from "element-plus/es/locale";
import { Microphone } from "@element-plus/icons-vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
// import draggable from "../utils/DraggableDirective";
import GpsVideo from "../components/GpsVideo.vue";
// import LeafletMap from "../components/LeafletMap.vue";
import GpsTimeLine from "../components/GpsTimeLine.vue";
import GpsSocket from "../utils/GpsSocket";
import { dateFormat } from "../utils/tools";
import GpsMap from "../components/GpsMapHistory.vue";
import GpsDownloadTime from "../components/GpsDownloadTime.vue";
export default {
  components: {
    Splitpanes,
    Pane,
    GpsVideo,
    GpsTimeLine,
    GpsMap,
    GpsDownloadTime,
  },
  // directives: {
  //   draggable,
  // },
  data() {
    return {
      value_st: "",
      options: [],
      show_donwload_time: false,
      treeDataTemp: [],
      defaultExpandedKeys: [],
      maxheight: window.innerHeight - 265,
      ct: null,
      is_play: false,
      channel_id: 1,
      isLoadEvent: false,
      select_tree_node: {},
      timeData: [],
      tab_act: "first",
      columns: [
        { field: "cph", label: this.$t("map.cph_name"), width: "100px" },

        {
          field: "channel_no",
          label: this.$t("map.channel_no"),
          width: "100px",
        },
        { field: "file_pos", label: this.$t("map.file_pos"), width: "100px" },
        {
          field: "start_time",
          label: this.$t("map.track_kssj"),
          width: "100px",
        },
        {
          field: "end_time",
          label: this.$t("map.track_jssj"),
          width: "100px",
        },
        { field: "file_size", label: this.$t("map.wjdx"), width: "100px" },
        { field: "alarm", label: this.$t("map.track_zt") },
      ],
      dataBackVideo: [],
      videoW: 1000,
      videoH: 500,
      mapBottomHeight: 219,
      search_times: [],
      startTime: "00:00:00",
      endTime: "23:59:59",
      search_date: dateFormat("YYYY-mm-dd", new Date()),
      // topHeight: 300,
      // bottomHeight: 300,
      paneSize: 15,
      csszProps: {
        children: "children",
        label: "name",
      },
      curr_tree_node: { id: 0 },
      propoption: {
        icon: "icon",
        value: "gps_id",
        label: "nodeName",
        children: "children",
      },
      treeData: [],
      query_key: "",
      cheigth: 600,
      map: null,
      BMap: null,
    };
  },
  created() {
    this.defaultExpandedKeys = ["a_0"];
    this.$api.tree_deptTree_Tree({}).then((res) => {
      this.treeDataTemp = res.data.resultData;
      this.defaultExpandedKeys[0] = this.treeDataTemp[0].value;
      this.delChannel(this.treeDataTemp);
      this.treeData = this.treeDataTemp;
    });
  },

  mounted() {
    // this.init_map();
    GpsSocket.addListener((data) => {
      console.log(data);
      if (data.type == "realtime") {
        if (
          typeof data.content.deviceid != "undefined" &&
          data.content.deviceid.indexOf(this.select_tree_node.devID) > -1 &&
          typeof data.content.content !== "undefined" &&
          typeof data.content.content.AVSourceList !== "undefined" &&
          data.content.content.AVSourceList
        ) {
          var ay = data.content.content.AVSourceList;
          var channel_obj = {};
          var channel_key = "";
          var channel_num = 0;

          var file_pos_idx = 0;

          var ay_pos = {
            a0: this.$t("map.zhongduanshebei"),
            a1: this.$t("map.fanyi98"),
          };

          var ay_files = [];
          for (var i = 0; i < ay.length; i++) {
            channel_key = "CH" + ay[i].ChannelNo;
            if (typeof channel_obj[channel_key] === "undefined") {
              channel_obj[channel_key] = [];
              channel_num++;
            }
            channel_obj[channel_key].push(ay[i]);

            if (parseInt(ay[i].FileSize) > 0) {
              // chs.push(ay[i].ChannelNo);
              ay_files.push({
                file_id: i + 1,
                cph: this.select_tree_node.vPlate,
                dev_id: this.select_tree_node.devID,
                channel_num: ay[i].ChannelNo,
                channel_no: "CH" + ay[i].ChannelNo,
                start_time: ay[i].StartDate.replace("T", " "),
                end_time: ay[i].EndDate.replace("T", " "),
                alarm:
                  ay[i].AlarmFlag > 0
                    ? this.$t("map.baojing")
                    : this.$t("map.zhengchang"),
                file_size:
                  (parseFloat(ay[i].FileSize) / 1024 / 1204).toFixed(2) + "M",
                file_pos: ay_pos["a" + file_pos_idx],
              });
            }
          }

          this.dataBackVideo = ay_files;
          let timeDataArray = [];
          let timeTempArray = [];
          let s_ay, e_ay;
          for (let p in channel_obj) {
            timeTempArray = [];
            channel_obj[p].forEach((it) => {
              s_ay = it.StartDate.split("T");
              e_ay = it.EndDate.split("T");
              timeTempArray.push({
                start_time: s_ay[s_ay.length - 1],
                end_time: e_ay[e_ay.length - 1],
                ch: p.replace("CH", ""),
              });
            });
            timeDataArray.push({ name: p, times: timeTempArray });
          }
          console.log("timeDataArray", timeDataArray);
          this.timeData = timeDataArray;
        }
      } else if (data.type == "answer") {
        if (this.is_play) {
          let url = this.get_video_url(this.select_tree_node.devID);
          console.log(url, this.$refs.video);
          setTimeout(() => {
            this.$refs.video.playUrl(url);
          }, 1000);
        }
      }
      // console.log("hcn", res);
    });

    window.onresize = () => {
      this.getWinSize();
    };
    this.getWinSize();
    setTimeout(() => {
      this.isLoadEvent = !this.isLoadEvent;
    }, 1000);

    // this.timeData = [
    //   {
    //     name: "CH1",
    //     times: [
    //       { start_time: "08:15:15", end_time: "09:20:30" },
    //       { start_time: "10:15:15", end_time: "12:20:30" },
    //     ],
    //   },
    // ];
  },
  methods: {
    ErpDialogEvent_time(obj) {
      switch (obj.type) {
        case "close":
          this.show_donwload_time = false;
          break;

        case "update":
          this.show_donwload_time = false;
          break;
      }
    },
    download_data() {
      if (this.dataBackVideo.length > 0) {
        console.log("download_data");
        this.show_donwload_time = true;
      } else {
        this.$message.error(this.$t("map.qingchaxun"));
      }
    },
    downloadFile(row) {
      console.log(row);
    },
    downFile(url) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = "xx";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      /*
       * download: HTML5新增的属性
       * url: 属性的地址必须是非跨域的地址
       */
    },
    delChannel(dataList) {
      dataList.forEach((it) => {
        if (it.nodetype == 2) {
          it.children = [];
        } else {
          if (it.children && it.children.length > 0) {
            this.delChannel(it.children);
          }
        }
      });
    },
    get_video_url(sn) {
      let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      let video_port_play = this.$store.getters.videoPort; //4022;
      return (
        "https://" +
        video_ip_play +
        ":4022/flv?port=10077&app=live&stream=" +
        sn +
        "_channel_" +
        this.channel_id +
        "_playback"
      );
      // return (
      //   "https://" +
      //   video_ip_play +
      //   ":" +
      //   video_port_play +
      //   "/flv?port=10077&app=live&stream=" +
      //   sn +
      //   "_channel_" +
      //   this.channel_id
      // );
    },
    setMapCar() {
      //获得定位
      this.$api
        .search_car_track_info({
          clickTime: this.ct,
          deviceid: this.select_tree_node.vDeviceID,
          cph: this.select_tree_node.vPlate,
        })
        .then((res) => {
          let row = res.data.data;
          if (row) {
            this.$refs.historyMap.posMarker(row);
            this.timer_pos = setTimeout(() => {
              this.setMapCar();
            }, 1000);
          }

          // console.log("ssss", res.data);
        });
    },
    gpsTimeEvent(obj) {
      console.log("gpsTimeEvent", obj);
      this.channel_id = parseInt(obj.ch);
      let ct = parseInt(
        this.getDateFromString(
          this.search_date + " " + obj.clickTime
        ).getTime() / 1000
      );
      this.ct = ct;

      this.timer_pos = setTimeout(() => {
        this.setMapCar();
      }, 1000);

      //回放视频
      let sn = this.select_tree_node.devID;
      let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
      let video_port = this.$store.getters.videoPlaybackPort; //this.$store.getters.orderPort; //"9500";
      let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      let video_port_play = this.$store.getters.videoPort; //4022;
      let dt = this.search_date.replace(/-/g, "").substr(2);
      let channel = parseInt(obj.ch);
      let st = dt + obj.start_time.replace(/:/g, "");
      let et = dt + obj.end_time.replace(/:/g, "");
      this.is_play = true;
      GpsSocket.sendMsg("order", {
        deviceid: sn /*"018124567840"*/,
        order: "9201",
        content:
          video_ip +
          "," +
          video_port +
          ",0," +
          channel +
          ",0,1,1,0,0," +
          st +
          "," +
          et +
          "",
      });
    },
    getDateFromString(str) {
      var reg = /^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/;
      var s = str.match(reg);
      var result = "";
      if (s) {
        result = new Date(s[1], s[2] - 1, s[3], s[4], s[5], s[6]);
      }
      return result;
    },
    search_data() {
      let day = this.search_date;
      let dt = this.search_date.replace(/-/g, "").substr(2);
      let st = this.startTime.replace(/:/g, "");
      let et = this.endTime.replace(/:/g, "");
      if (this.select_tree_node.devID) {
        let sn = this.select_tree_node.devID;
        this.is_play = false;

        GpsSocket.sendMsg("order", {
          deviceid: sn,
          order: "9205",
          content: "0," + (dt + st) + "," + (dt + et) + ",0,0,1,1",
        });
      } else {
        this.$message.error(this.$t("map.qingxuanze"));
      }
    },
    getWinSize() {
      let height = parseFloat(window.innerHeight) - this.mapBottomHeight - 50;
      this.videoH = height;
    },
    nodeClick(node) {
      console.log(node);
      this.select_tree_node = node;
    },
    handleDragging(e) {
      console.log(e);
      const splitScreen = this.$refs.splitScreen;
      const rect = splitScreen.getBoundingClientRect();
      const height = e.clientY - rect.top;
      this.topHeight = height;
      this.bottomHeight = rect.height - height;
    },
    filterMethod(query, node) {
      return node.nodeName.includes(query);
    },
    onQueryChanged(query) {
      this.$refs.treeRef.filter(query);
    },
    init_map() {
      this.BMap = window.BMap;
      this.map = new this.BMap.Map("allmap");
      var point = new this.BMap.Point(115.487135, 38.873433);
      this.map.centerAndZoom(point, 13);
      this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // this.map.setMapStyle({ style: "midnight" });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  box-sizing: border-box;
  padding: 10px;
  .s-it {
    padding-bottom: 5px;
  }
  ::v-deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
    width: 100%;
  }
  ::v-deep(.el-select) {
    width: 100%;
  }
}
#allmap {
  width: 100%;
  height: 100%;
}
.split-screen {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  .top,
  .bottom {
    height: 50%;
    overflow: auto;
  }
  .top {
    .row {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      .row-r {
        flex: 1;
        overflow: hidden;
      }
    }
  }
  .divider {
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #ccc;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #999;
    cursor: ns-resize;
  }
}
::v-deep(.el-tree-node__content) {
  line-height: 26px;
  font-size: 14px;
}
.left-1 {
  border: 1px solid #ccc;
}
.searchs {
  padding: 5px 10px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.searchs .el-select {
  margin-right: 5px;
}
.el-tree {
  padding-top: 5px;
}
.el-icon {
  margin-right: 2px;
}
.el-drawer__title {
  font-size: 15px;
  color: #fff;
}
::v-deep .mapbox .tool {
  font-size: 14px;
  padding: 0px 5px;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 7px;
}
::v-deep .el-drawer__title {
  font-size: 14px !important;
  color: #333;
  border-left: 3px solid #2c78bf;
  padding-left: 5px;
}
.xfwz {
}
.xf-1 {
  border-right: 1px solid #ededed;
  border-radius: 5px;
}
.xf-2 {
  border-radius: 5px;
  max-width: 74% !important;
}
::v-deep .xf-2 .el-textarea__inner {
  background: #fafafa !important;
  margin-top: 0px;
  box-shadow: none;
  border: 1px solid #ededed;
}
.xf-3 {
  border-radius: 5px;
}
::v-deep .xfwz .el-tree {
  padding-top: 0px !important;
  margin-top: -5px;
}
.xxs {
  padding-right: 5px;
  color: #2c78bf;
}
::v-deep .ttr .el-table th.el-table__cell {
  color: #333 !important;
}
.cssz-1 {
}
::v-deep .cssz-1 .el-tree {
  background: #fafafa;
  border-radius: 5px;
}
::v-deep .clxx .el-table--small {
  border: 1px solid #eee !important;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.left {
  box-sizing: border-box;
  padding: 5px 0px 5px 5px;
  border-right: 1px solid #ccc;
}
.search {
  padding: 5px;
  border: 1px solid #eee;
  background: #fafafa;
}
.ss {
  padding: 5px;
  background: #fff;
}
::v-deep .bottom .el-tabs__header {
  padding-top: 10px;
}
.bb {
  border: 1px solid #ccc;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 7px;
  padding-bottom: 10px;
}
::v-deep .bb .el-table__inner-wrapper {
  margin-bottom: 5px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top {
  height: 30px;
  line-height: 30px;
}
::v-deep .ss .el-button--small {
  height: 30px;
}
::v-deep .ss .el-date-editor.el-input {
  height: 30px;
}
::v-deep .ss .search .el-select .el-input {
  height: 30px;
}
</style>
